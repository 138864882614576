import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { History } from 'history';
import { OrderEntryHeader } from '../../api';
import { FileResult } from '../../api/models/api-shared.models';
import {
  CreateSmartOrderEntryHeaderRequest,
  DownloadOrderImportSpreadsheetTemplateRequest,
  ImportOrderDelimitedRequest,
  ImportOrderDelimitedResultData,
  ImportOrderSpreadsheetRequest,
  ImportOrderSpreadsheetResultData,
  ValidateImportOrderDelimitedRequest,
  ValidateImportOrderDelimitedResultData,
  ValidateImportOrderSpreadsheetRequest,
  ValidateImportOrderSpreadsheetResultData,
} from '../../api/models/order-import.models';
import OrderEntryHeaderService from '../../api/services/order-entry-header.service';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import {
  AppRoutes,
  Resolution,
  RouteName,
  UserActivityAction,
  UserActivityActionSummary,
  UserActivityPageName,
} from '../../models';
import { editUserCustomerSettings, globalSlice, logUserActivity } from '../common';
import { setErrorDialogContent } from '../common/global.thunks';
import { resetParListDetailsByProduct } from '../par-management';
import { AppDispatch, AppThunk } from '../store';
import { setActiveCartById } from './orders.thunks';
import { reorderSlice } from './reorder.slice';

const service = OrderEntryHeaderService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Retrieves file data of a csv template containing records for each product in the specified product list
 * @param request - the request sent to the api
 * @param onSuccess - the callback method executed when api repsonse is successful
 * @param onError - the callback method executed when api repsonse is unsuccessful
 * @returns
 */
export const getOrderImportSpreadsheetTemplate =
  (
    request: DownloadOrderImportSpreadsheetTemplateRequest,
    onSuccess: (file: FileResult) => void,
    onError: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await service.getOrderImportSpreadsheetTemplate(request);
      if (data.IsSuccess) {
        onSuccess(data.ResultObject);
      } else {
        onError();
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            title: 'Failed to download template',
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error) {
      onError();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to evaluate the validity of records contained in the selected file of the request
 * @param request - the request sent to the api
 * @param onSuccess - the callback method executed when api repsonse is successful
 * @param onError - the callback method executed when api repsonse is unsuccessful
 * @returns
 */
export const validateImportOrderSpreadsheet =
  (
    request: ValidateImportOrderSpreadsheetRequest,
    onSuccess: (result: ValidateImportOrderSpreadsheetResultData) => void,
    onError: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      // Call the API
      const { data } = await service.validateImportOrderSpreadsheet(request);

      // Handle results
      if (data.IsSuccess) {
        onSuccess(data.ResultObject);
      } else {
        onError();
        dispatch(setErrorDialogContent('Error Validating Spreadsheet', data.ErrorMessages));
      }
    } catch (error) {
      onError();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to evaluate the validity of records contained in the selected file of the request
 * @param request - the request sent to the api
 * @param onSuccess - the callback method executed when api repsonse is successful
 * @param onError - the callback method executed when api repsonse is unsuccessful
 * @returns
 */
export const validateImportOrderDelimited =
  (
    request: ValidateImportOrderDelimitedRequest,
    onSuccess: (result: ValidateImportOrderDelimitedResultData) => void,
    onError: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      // Call the API
      const { data } = await service.validateImportOrderDelimited(request);

      // Handle results
      if (data.IsSuccess) {
        onSuccess(data.ResultObject);
      } else {
        onError();
        dispatch(setErrorDialogContent('Error Validating Spreadsheet', data.ErrorMessages));
      }
    } catch (error) {
      onError();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to create a new product list from the data contained in a csv file
 * @param request - the request sent to the api
 * @param resolution - passed to the logUserActivity thunk
 * @param onSuccess - the callback method executed when api repsonse is successful
 * @param onError - the callback method executed when api repsonse is unsuccessful
 * @returns
 */
export const importOrderSpreadsheet =
  (
    request: ImportOrderSpreadsheetRequest,
    resolution: Resolution,
    onSuccess: (result: ImportOrderSpreadsheetResultData) => void,
    onError: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      // Call the API
      const { data } = await service.importOrderSpreadsheet(request);

      // Handle results
      if (data.IsSuccess) {
        onSuccess(data.ResultObject);
        dispatch(
          logUserActivity({
            action: UserActivityAction.CreateOrder,
            pageName: UserActivityPageName.OrderImport,
            actionSummary: UserActivityActionSummary.CreateOrderFromImport,
            resolution: resolution,
          })
        );
      } else {
        onError();
        dispatch(setErrorDialogContent('Error Validating Spreadsheet', data.ErrorMessages));
      }
    } catch (error) {
      onError();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sends a request to the api to create a new product list from the delimited data contained in the selected file
 * @param request - the request sent to the api
 * @param onSuccess - the callback method executed when api repsonse is successful
 * @param onError - the callback method executed when api repsonse is unsuccessful
 * @returns
 */
export const importOrderDelimited =
  (
    request: ImportOrderDelimitedRequest,
    onSuccess: (result: ImportOrderDelimitedResultData) => void,
    onError: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      // Call the API
      const { data } = await service.importOrderDelimited(request);

      // Handle results
      if (data.IsSuccess) {
        onSuccess(data.ResultObject);
      } else {
        onError();
        dispatch(setErrorDialogContent('Error Validating Spreadsheet', data.ErrorMessages));
      }
    } catch (error) {
      onError();
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export const createSmartOrder =
  (request: CreateSmartOrderEntryHeaderRequest, history: History): AppThunk<Promise<OrderEntryHeader | undefined>> =>
  async (dispatch, getState): Promise<OrderEntryHeader | undefined> => {
    try {
      // Call the API
      const { data } = await service.createSmartOrderEntryHeader(request);

      // Handle results
      if (data.IsSuccess) {
        if (data.InformationMessages?.length > 0) {
          dispatch(reorderSlice.actions.setSmartOrderResult(data.InformationMessages));
          dispatch(toggleSmartOrderDialog(true));
        }

        // show pars in order entry
        const customer = getState().customer.selectedCustomer;
        if (customer) {
          dispatch(resetParListDetailsByProduct());
          dispatch(editUserCustomerSettings(customer, true, request.ParHeaderId));
        }

        await dispatch(
          setActiveCartById(data.ResultObject.OrderEntryHeaderId, () => {
            navigateToSmartOrderReview(history, data.ResultObject);
          })
        );

        // return data.ResultObject;
      } else {
        dispatch(setErrorDialogContent(undefined, data.ErrorMessages));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
    return undefined;
  };

export const resetSmartOrderState = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(reorderSlice.actions.resetSmartOrderState());
};

/**
 * Sets the showSmartOrderDialog value in the reorder slice
 *
 * @param open - The value showSmartOrderDialog will be set to
 * @returns NULL
 */
export const toggleSmartOrderDialog =
  (open: boolean): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(reorderSlice.actions.setShowSmartOrderDialog(open));
  };

/**
 * Navigates the user to the order review page
 *
 * @param history - Used to change the URL
 * @param order - order object to call on the correct order reivew
 * @returns NULL
 */
export const navigateToSmartOrderReview = (history: History, order: OrderEntryHeader) => {
  history.replace(AppRoutes[RouteName.OrderReview].Path + '/' + order.CustomerId + '/' + order.OrderEntryHeaderId);
};
