import { UserActivityPageName } from './user-activity-report.models';

export enum RouteName {
  AccountsReceivables = 'AccountsReceivables',
  AddFromList = 'AddFromList',
  CookieAcceptance = 'CookieAcceptance',
  CustomerSupport = 'CustomerSupport',
  DeliveryDetails = 'DeliveryDetails',
  DND = 'DND',
  GetStarted = 'GetStarted',
  Home = 'Home',
  Insights = 'Insights',
  InsightsReport = 'InsightsReport',

  InventoryReports = 'InventoryReports',

  InventoryCostAnalysis = 'InventoryCostAnalysis',
  InventoryConsumption = 'InventoryConsumption',
  InventoryValuation = 'InventoryValuation',

  InventorySnapshot = 'InventorySnapshot',
  InventoryWorksheet = 'InventoryWorksheet',
  InventoryEntry = 'InventoryEntry',
  InventoryManagement = 'InventoryManagement',
  InvoiceDetail = 'InvoiceDetail',
  Invoices = 'Invoices',
  Login = 'Login',
  NoUserInformation = 'NoUserInformation',
  OrderHistory = 'OrderHistory',
  OrderDetails = 'OrderDetails',
  OrderEntry = 'OrderEntry',
  OrderReview = 'OrderReview',
  OrderConfirmation = 'OrderConfirmation',
  OrderImport = 'OrderImport',
  ParManagement = 'ParManagement',
  ParMaintenance = 'ParMaintenance',
  VendorManagement = 'VendorManagement',
  VendorDetails = 'VendorDetails',
  AddFromVendor = 'AddFromVendor',
  ProductSearch = 'ProductSearch',
  ProductDetails = 'ProductDetails',
  VendorProductDetail = 'VendorProductDetail',
  TermsOfService = 'TermsOfService',
  ProductList = 'ProductList',
  ProductListCatalogSearch = 'ProductListCatalogSearch',
  ProductListManagement = 'ProductListManagement',
  ProductListNotifications = 'ProductListNotifications',
  ProductListImport = 'ProductListImport',
  ProductListExport = 'ProductListExport',
  ProductListDiary = 'ProductListDiary',
  SearchAnalysis = 'SearchAnalysis',
  SimilarProductSearch = 'SimilarProductSearch',
  UI = 'UI',
  UserProfile = 'UserProfile',
  VerifyContactEmail = 'VerifyContactEmail',
  CreateMessage = 'CreateMessage',
  MessageCenter = 'MessageCenter',
  MessageGroups = 'MessageGroups',
  ViewMessageGroup = 'ViewMessageGroup',
  CreateMessageGroup = 'CreateMessageGroup',
  UpdateMessageGroup = 'UpdateMessageGroup',
  InboxMessages = 'InboxMessages',
  ViewInboxMessage = 'ViewInboxMessage',
  DraftMessages = 'DraftMessages',
  ViewDraftMessage = 'ViewDraftMessage',
  SentMessages = 'SentMessages',
  ViewSentMessage = 'ViewSentMessage',
  LinkExpired = 'LinkExpired',
  AccountTutorials = 'AccountTutorials',
  OrderingTutorials = 'OrderingTutorials',
  InvoiceTutorials = 'InvoiceTutorials',
  ListManagementTutorials = 'ListManagementTutorials',
  ParManagementTutorials = 'ParManagementTutorials',
  RepeatOrder = 'RepeatOrder',

  Video = 'Video',
  Communications = 'Communications',
  ManageUsers = 'ManageUsers',
  ManageUsersExport = 'ManageUsersExport',
  RemoveMultipleUsers = 'RemoveMultipleUsers',
  CustomerGroups = 'CustomerGroups',
  ViewCustomerGroup = 'ViewCustomerGroup',
  CreateCustomerGroup = 'CreateCustomerGroup',

  SmartOrder = 'SmartOrder',
}

export type AppRoute = {
  DisplayName: string;
  Path: string;
  Params?: string[];
  QueryParams?: { [key: string]: string };
  UserActivityPageName?: UserActivityPageName;
};

// When adding routes, order from most specific path to least specific path

export const AppRoutes: Record<RouteName, AppRoute> = {
  [RouteName.Home]: { DisplayName: 'Home', Path: '/', UserActivityPageName: UserActivityPageName.Home },
  [RouteName.NoUserInformation]: {
    DisplayName: 'No User Information',
    Path: '/user-unavailable',
    UserActivityPageName: UserActivityPageName.NoUserInformation,
  },
  [RouteName.OrderHistory]: {
    DisplayName: 'Order history',
    Path: '/order-history',
    UserActivityPageName: UserActivityPageName.OrderHistory,
  },
  [RouteName.OrderDetails]: {
    DisplayName: 'Order details',
    Path: '/submitted-orders',
    Params: [':customerId', ':orderKey'],
    UserActivityPageName: UserActivityPageName.OrderDetails,
  },
  [RouteName.OrderEntry]: {
    DisplayName: 'Order entry',
    Path: '/order-entry',
    Params: [':orderId'],
    UserActivityPageName: UserActivityPageName.OrderEntry,
  },
  [RouteName.OrderReview]: {
    DisplayName: 'Order review',
    Path: '/order-review',
    Params: [':customerId', ':orderId'],
    UserActivityPageName: UserActivityPageName.OrderReview,
  },
  [RouteName.OrderConfirmation]: {
    DisplayName: 'Order confirmation',
    Path: '/order-confirmation',
    Params: [':customerId', ':orderId'],
    UserActivityPageName: UserActivityPageName.OrderConfirmation,
  },
  [RouteName.OrderImport]: {
    DisplayName: 'Order import',
    Path: '/order/import',
    UserActivityPageName: UserActivityPageName.OrderImport,
  },
  [RouteName.SmartOrder]: {
    DisplayName: 'Smart order',
    Path: '/smart-order',
    UserActivityPageName: UserActivityPageName.SmartOrder,
  },
  [RouteName.ProductSearch]: {
    DisplayName: 'Search results',
    Path: '/search',
    Params: [':customerId'],
    UserActivityPageName: UserActivityPageName.Search,
  },
  [RouteName.SimilarProductSearch]: {
    DisplayName: 'Search results',
    Path: '/search-similar',
    Params: [':customerId'],
    UserActivityPageName: UserActivityPageName.FindSimilarSearch,
  },
  [RouteName.ProductDetails]: {
    DisplayName: 'Product details',
    Path: '/product-details',
    Params: [':customerId', ':productKey', ':orderId?'],
    UserActivityPageName: UserActivityPageName.ProductDetails,
  },
  [RouteName.TermsOfService]: {
    DisplayName: 'Terms of Service & Privacy Policy',
    Path: '/service-terms',
  },
  [RouteName.CookieAcceptance]: {
    DisplayName: 'Cookies',
    Path: '/cookie-acceptance',
  },
  [RouteName.ProductList]: {
    DisplayName: 'View list',
    Path: '/list-management',
    Params: [':productListHeaderId', ':customerId', ':sortByType?'],
    UserActivityPageName: UserActivityPageName.ListManagement,
  },
  [RouteName.ProductListCatalogSearch]: {
    DisplayName: 'Search results',
    Path: '/list-management/search',
    Params: [':productListHeaderId', ':customerId'],
    UserActivityPageName: UserActivityPageName.ListDetailSearch,
  },
  [RouteName.ProductListManagement]: {
    DisplayName: 'List management',
    Path: '/list-management',
  },
  [RouteName.ProductListNotifications]: {
    DisplayName: 'List notifications',
    Path: '/list-notifications',
    UserActivityPageName: UserActivityPageName.ListNotification,
  },

  [RouteName.AddFromVendor]: {
    DisplayName: 'Add From Vendor',
    Path: '/list-management/add-from-vendor',
    Params: [':productListHeaderId', ':customerId', ':vendorId'],
    UserActivityPageName: UserActivityPageName.AddFromVendor, // todo
  },
  [RouteName.AddFromList]: {
    DisplayName: 'Add From List',
    Path: '/list-management/add-from-list',
    Params: [':productListHeaderId', ':customerId', ':fromProductListHeaderId'],
  },
  [RouteName.ProductListImport]: {
    DisplayName: 'List import',
    Path: '/list-management/list-import',
  },
  [RouteName.ProductListExport]: {
    DisplayName: 'Export',
    Path: '/list-management/:productListHeaderId/:customerId/export',
    Params: [':productListHeaderId', ':customerId'],
  },
  [RouteName.ProductListDiary]: {
    DisplayName: 'List diary',
    Path: '/list-management/:productListHeaderId/:customerId/diary',
    Params: [':productListHeaderId', ':customerId'],
  },
  [RouteName.UserProfile]: {
    DisplayName: 'Profile',
    Path: '/user-profile',
    UserActivityPageName: UserActivityPageName.UserProfile,
  },
  [RouteName.ManageUsers]: {
    DisplayName: 'Manage users',
    Path: '/manage-users',
    UserActivityPageName: UserActivityPageName.ManageUsers,
  },
  [RouteName.RemoveMultipleUsers]: {
    DisplayName: 'Remove multiple users',
    Path: '/manage-users/remove-multiple-users',
    UserActivityPageName: UserActivityPageName.ManageUsers,
  },
  [RouteName.ManageUsersExport]: {
    DisplayName: 'Export',
    Path: '/manage-users/export',
    UserActivityPageName: UserActivityPageName.ManageUsersExport,
  },
  [RouteName.CustomerSupport]: {
    DisplayName: 'Customer support',
    Path: '/customer-support',
    UserActivityPageName: UserActivityPageName.CustomerSupport,
  },
  [RouteName.AccountTutorials]: {
    DisplayName: 'Account',
    Path: '/customer-support/account',
    UserActivityPageName: UserActivityPageName.CustomerSupportAccount,
  },
  [RouteName.OrderingTutorials]: {
    DisplayName: 'Ordering',
    Path: '/customer-support/ordering',
    UserActivityPageName: UserActivityPageName.CustomerSupportOrdering,
  },
  [RouteName.InvoiceTutorials]: {
    DisplayName: 'Invoices',
    Path: '/customer-support/invoices',
    UserActivityPageName: UserActivityPageName.CustomerSupportInvoices,
  },
  [RouteName.ListManagementTutorials]: {
    DisplayName: 'List management',
    Path: '/customer-support/list-management',
    UserActivityPageName: UserActivityPageName.CustomerSupportListManagement,
  },
  [RouteName.ParManagementTutorials]: {
    DisplayName: 'Inventory',
    Path: '/customer-support/inventory',
    UserActivityPageName: UserActivityPageName.CustomerSupportParManagement,
  },
  [RouteName.CustomerGroups]: {
    DisplayName: 'Customer Groups',
    Path: '/customer-groups',
    UserActivityPageName: UserActivityPageName.CustomerGroups,
  },
  [RouteName.CreateCustomerGroup]: {
    DisplayName: 'Customer Groups',
    Path: '/customer-groups/create',
    UserActivityPageName: UserActivityPageName.CreateCustomerGroup,
  },
  [RouteName.ViewCustomerGroup]: {
    DisplayName: 'View customer group',
    Path: '/customer-groups/:CustomerGroupHeaderId',
    Params: [':CustomerGroupHeaderId'],
    UserActivityPageName: UserActivityPageName.ViewCustomerGroup,
  },
  [RouteName.RepeatOrder]: {
    DisplayName: 'Repeat order',
    Path: '/repeat-order',
    UserActivityPageName: UserActivityPageName.RepeatOrder,
  },
  [RouteName.MessageCenter]: {
    DisplayName: 'Message center',
    Path: '/message-center',
  },
  [RouteName.CreateMessage]: {
    DisplayName: 'New Message',
    Path: '/message-center/inbox/new-message',
    UserActivityPageName: UserActivityPageName.CreateMessage,
  },
  [RouteName.MessageGroups]: {
    DisplayName: 'Message groups',
    Path: '/message-center/message-groups',
    UserActivityPageName: UserActivityPageName.MessageGroups,
  },
  [RouteName.ViewMessageGroup]: {
    DisplayName: 'View message group',
    Path: '/message-center/message-groups/:messageGroupId',
    Params: [':messageGroupId'],
    UserActivityPageName: UserActivityPageName.ViewMessageGroup,
  },
  [RouteName.CreateMessageGroup]: {
    DisplayName: 'Create message group',
    Path: '/message-center/message-groups/create',
    UserActivityPageName: UserActivityPageName.CreateMessageGroup,
  },
  [RouteName.UpdateMessageGroup]: {
    DisplayName: 'Manage message group',
    Path: '/message-center/message-groups/:messageGroupId/update',
    Params: [':messageGroupId'],
    UserActivityPageName: UserActivityPageName.UpdateMessageGroup,
  },
  [RouteName.Communications]: {
    DisplayName: 'Communications',
    Path: '/communications',
    Params: [],
    UserActivityPageName: UserActivityPageName.CommunicationPreferences,
  },
  [RouteName.InboxMessages]: {
    DisplayName: 'Inbox',
    Path: '/message-center/inbox',
    UserActivityPageName: UserActivityPageName.InboxMessages,
  },
  [RouteName.ViewInboxMessage]: {
    DisplayName: 'View Message',
    Path: '/message-center/inbox/:messageId',
    Params: [':messageId'],
    UserActivityPageName: UserActivityPageName.ViewInboxMessage,
  },

  [RouteName.DraftMessages]: {
    DisplayName: 'Draft',
    Path: '/message-center/draft',
    UserActivityPageName: UserActivityPageName.DraftMessages,
  },
  [RouteName.ViewDraftMessage]: {
    DisplayName: 'View Message',
    Path: '/message-center/draft/:messageId',
    Params: [':messageId'],
    UserActivityPageName: UserActivityPageName.ViewDraftMessage,
  },

  [RouteName.SentMessages]: {
    DisplayName: 'Sent',
    Path: '/message-center/sent',
    UserActivityPageName: UserActivityPageName.SentMessages,
  },
  [RouteName.ViewSentMessage]: {
    DisplayName: 'Sent Message',
    Path: '/message-center/sent/:messageId',
    Params: [':messageId'],
    UserActivityPageName: UserActivityPageName.ViewSentMessage,
  },

  [RouteName.SearchAnalysis]: {
    DisplayName: 'Search Analysis',
    Path: '/search-analysis',
    UserActivityPageName: UserActivityPageName.SearchAnalysis,
  },
  [RouteName.UI]: {
    DisplayName: 'CF UI',
    Path: '/cf-ui',
    UserActivityPageName: UserActivityPageName.UI,
  },
  [RouteName.VerifyContactEmail]: {
    DisplayName: 'Verify Contact Email',
    Path: '/VerifyContactEmail',
  },
  [RouteName.AccountsReceivables]: {
    DisplayName: 'A/P statements',
    Path: '/ap-statements',
    UserActivityPageName: UserActivityPageName.AccountsReceivableStatements,
  },
  [RouteName.Invoices]: {
    DisplayName: 'Invoices',
    Path: '/invoices',
    UserActivityPageName: UserActivityPageName.Invoices,
  },
  [RouteName.InvoiceDetail]: {
    DisplayName: 'Invoice details',
    Path: '/invoice-details',
    UserActivityPageName: UserActivityPageName.InvoiceDetail,
    Params: [':invoice', ':businessUnitERPKey', ':operationCompanyNo'],
  },
  [RouteName.Insights]: {
    DisplayName: 'Insights',
    Path: '/insights',
    UserActivityPageName: UserActivityPageName.Insights,
  },
  [RouteName.InsightsReport]: {
    DisplayName: 'Report',
    Path: '/insights-report',
    UserActivityPageName: UserActivityPageName.InsightsReport,
    Params: [':searchHeaderId'],
  },
  [RouteName.GetStarted]: {
    DisplayName: 'Get Started',
    Path: '/getstarted',
    QueryParams: {
      bu: 'bu',
      cta: 'cta',
    },
  },
  [RouteName.LinkExpired]: {
    DisplayName: 'Link Expired',
    Path: '/linkexpired',
  },
  [RouteName.Login]: {
    DisplayName: 'Login',
    Path: '*',
  },
  [RouteName.DND]: {
    DisplayName: 'DND',
    Path: '/dnd',
  },
  [RouteName.Video]: {
    DisplayName: 'Video',
    Path: '/poc-video',
  },
  [RouteName.DeliveryDetails]: {
    DisplayName: 'Delivery details',
    Path: '/deliveries',
    UserActivityPageName: UserActivityPageName.DeliveryDetails,
    Params: [':deliveryKey'],
  },
  [RouteName.ParManagement]: {
    DisplayName: 'Par management',
    Path: '/par-management',
    UserActivityPageName: UserActivityPageName.ParManagement,
  },
  [RouteName.VendorProductDetail]: {
    DisplayName: 'Vendor product detail',
    Path: '/vendor-management/product-detail',
    Params: [':customerId', ':thirdPartyVendorProductId'],
    UserActivityPageName: UserActivityPageName.VendorProductDetails,
  },
  [RouteName.VendorDetails]: {
    DisplayName: 'Vendor details',
    Path: '/vendor-management',
    Params: [':vendorId', ':customerId'],
    UserActivityPageName: UserActivityPageName.VendorDetails,
  },
  [RouteName.VendorManagement]: {
    DisplayName: 'Vendor management',
    Path: '/vendor-management',
    UserActivityPageName: UserActivityPageName.VendorManagement,
  },
  [RouteName.ParMaintenance]: {
    DisplayName: 'Par maintenance',
    Path: '/par-maintenance',
    Params: [':listId'],
  },
  [RouteName.InventoryReports]: {
    DisplayName: 'Inventory reports',
    Path: '/inventory-reports',
  },
  [RouteName.InventoryCostAnalysis]: {
    DisplayName: 'Category cost analysis report',
    Path: '/inventory-cost-analysis',
    Params: [':customerId'],
  },
  [RouteName.InventoryConsumption]: {
    DisplayName: 'Inventory consumption report',
    Path: '/inventory-consumption',
    Params: [':customerId'],
  },
  [RouteName.InventoryValuation]: {
    DisplayName: 'Inventory valuation report',
    Path: '/inventory-valuation',
    Params: [':customerId'],
  },

  [RouteName.InventorySnapshot]: {
    DisplayName: 'Inventory snapshot',
    Path: '/inventory-snapshot',
    Params: [':headerId', ':customerId'],
  },
  [RouteName.InventoryWorksheet]: {
    DisplayName: 'Inventory worksheet',
    Path: '/inventory-worksheet',
    Params: [':customerId'],
  },
  [RouteName.InventoryEntry]: {
    DisplayName: 'Inventory entry',
    Path: '/inventory-entry',
    Params: [':headerId', ':customerId'],
  },
  [RouteName.InventoryManagement]: {
    DisplayName: 'Inventory management',
    Path: '/inventory-management',
  },
};

export const getPageTitle = (pathname: string): string | undefined => {
  let title: string | undefined = undefined;
  Object.values(AppRoutes).forEach((route) => {
    if (pathname.toLowerCase() === route.Path.toLowerCase()) {
      title = route.DisplayName;
    }
  });
  return title;
};
