import { CustomerSupportType, OverviewSlideGroups } from '../../../api';
import { CustomerSupportTutorialItem } from '../../../models';

export const parManagementTutorials: CustomerSupportTutorialItem[] = [
  {
    tutorialTitle: 'Create and manage par guides',
    tutorialText: 'Create and manage par values for your items to view them when ordering.',
    customerSupportType: CustomerSupportType.ParManagement,
    overviewGroup: OverviewSlideGroups.ParManagement,
  },
  {
    tutorialTitle: 'Manage inventory',
    tutorialText:
      'Learn how to take on-hand inventory levels, run inventory reports, and create and manage third party items.',
    customerSupportType: CustomerSupportType.ParManagement,
    overviewGroup: OverviewSlideGroups.Inventory,
  },
];
