import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerSupportType, OverviewSlideGroups } from '../../api/models/customer-support.enums';
import { OverviewViewStatus, Slide } from '../../api/models/customer-support.models';
import { CustomerSupportSlides } from '../../models/customer-support.models';

interface CustomerSupportState {
  isLoading: boolean;
  customerSupportSlides: CustomerSupportSlides;
  overviewViewStatus: OverviewViewStatus;
  showOverviewDialog: boolean;
  showSkipDialog: boolean;
  isOverviewDialogLoading: boolean;
  currentSlideGroup: OverviewSlideGroups | undefined;
  currentSlides: Slide[] | undefined;
}

const initialState: CustomerSupportState = {
  isLoading: false,
  customerSupportSlides: {
    [CustomerSupportType.Account]: [],
    [CustomerSupportType.Invoices]: [],
    [CustomerSupportType.ListManagement]: [],
    [CustomerSupportType.Ordering]: [],
    [CustomerSupportType.ParManagement]: [],
  },
  overviewViewStatus: {
    [OverviewSlideGroups.Invoices]: undefined,
    [OverviewSlideGroups.ARStatements]: undefined,
    [OverviewSlideGroups.Insights]: undefined,
    [OverviewSlideGroups.ListManagement]: undefined,
    [OverviewSlideGroups.NewOrder]: undefined,
    [OverviewSlideGroups.ImportOrder]: undefined,
    [OverviewSlideGroups.ItemEntry]: undefined,
    [OverviewSlideGroups.UserProfile]: undefined,
    [OverviewSlideGroups.MessageCenter]: undefined,
    [OverviewSlideGroups.CommunicationsPreferences]: undefined,
    [OverviewSlideGroups.ParManagement]: undefined,
    [OverviewSlideGroups.Inventory]: undefined,
  },
  showOverviewDialog: false,
  showSkipDialog: false,
  isOverviewDialogLoading: false,
  currentSlideGroup: undefined,
  currentSlides: undefined,
};

export const customerSupportSlice = createSlice({
  name: 'customer-support',
  initialState: initialState,
  reducers: {
    resetState: () => initialState,
    setIsLoading: (state: CustomerSupportState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCustomerSupportOrderingSlides: (state: CustomerSupportState, action: PayloadAction<Slide[]>) => {
      state.customerSupportSlides[0] = action.payload;
    },
    setCustomerSupportFinanceSlides: (state: CustomerSupportState, action: PayloadAction<Slide[]>) => {
      state.customerSupportSlides[1] = action.payload;
    },
    setCustomerSupportAccountSlides: (state: CustomerSupportState, action: PayloadAction<Slide[]>) => {
      state.customerSupportSlides[2] = action.payload;
    },
    setCustomerSupportListManagementSlides: (state: CustomerSupportState, action: PayloadAction<Slide[]>) => {
      state.customerSupportSlides[3] = action.payload;
    },
    setCustomerSupportParManagementSlides: (state: CustomerSupportState, action: PayloadAction<Slide[]>) => {
      state.customerSupportSlides[4] = action.payload;
    },
    setOverviewViewedSlides: (state: CustomerSupportState, action: PayloadAction<OverviewViewStatus>) => {
      state.overviewViewStatus = action.payload;
    },
    setShowOverviewDialog: (state: CustomerSupportState, action: PayloadAction<boolean>) => {
      state.showOverviewDialog = action.payload;
    },
    setShowSkipDialog: (state: CustomerSupportState, action: PayloadAction<boolean>) => {
      state.showSkipDialog = action.payload;
    },
    setIsOverviewDialogLoading: (state: CustomerSupportState, action: PayloadAction<boolean>) => {
      state.isOverviewDialogLoading = action.payload;
    },
    setCurrentSlideGroup: (state: CustomerSupportState, action: PayloadAction<OverviewSlideGroups>) => {
      state.currentSlideGroup = action.payload;
    },
    setCurrentSlides: (state: CustomerSupportState, action: PayloadAction<Slide[]>) => {
      state.currentSlides = action.payload;
    },
    resetSlideGroup: (state: CustomerSupportState) => {
      state.currentSlideGroup = initialState.currentSlideGroup;
      state.isOverviewDialogLoading = initialState.isOverviewDialogLoading;
      state.currentSlides = initialState.currentSlides;
      state.showOverviewDialog = initialState.showOverviewDialog;
      state.showSkipDialog = initialState.showSkipDialog;
    },
  },
});
