export enum CustomerSupportType {
  Ordering,
  Invoices,
  Account,
  ListManagement,
  ParManagement,
}

export enum OverviewSlideGroups {
  Invoices = 0,
  ARStatements = 1,
  Insights = 2,
  ListManagement = 3,
  NewOrder = 4,
  ImportOrder = 5,
  ItemEntry = 6,
  UserProfile = 7,
  MessageCenter = 8,
  CommunicationsPreferences = 9,
  ParManagement = 10,
  Inventory = 11,
}

export enum ViewStatus {
  NotViewed = 0,
  Viewed = 1,
}
