import { ServiceResult } from '../models/api-shared.models';
import {
  UnitOfMeasures,
  VendorProduct,
  VendorProductDeleteRequest,
  VendorProductListRequest,
  VendorProductRequest,
} from '../models/vendor-products.models';
import ApiService from './api-service';

function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class VendorProductService extends ApiService {
  private static classInstance: VendorProductService;

  private constructor() {
    super('ThirdPartyVendor');
  }

  // TODO: Remove async for all methods during API Integration
  // Check par-list-detail.service.ts for reference
  public async getVendorProductList(request: VendorProductListRequest): ServiceResult<VendorProduct[]> {
    //TODO: remove after API integraion
    // await delay(1000);
    // //TODO: real response will be sent after API integration
    // const response = await getMockApiResponse('getVendorProductList', request);
    // return response as unknown as ServiceResult<VendorProduct[]>;

    // actual API call
    return this.post(`${this.persistUrl}/GetThirdPartyVendorProducts`, request);
  }

  public getVendorProduct(request: VendorProductRequest): ServiceResult<VendorProduct> {
    return this.get(`${this.persistUrl}/GetThirdPartyVendorProduct/`, request);
  }

  public createVendorProduct(request: VendorProduct): ServiceResult<VendorProduct> {
    return this.post(`${this.persistUrl}/CreateThirdPartyVendorProduct`, request);
  }

  public updateVendorProduct(request: VendorProduct): ServiceResult<VendorProduct> {
    return this.post(`${this.persistUrl}/UpdateThirdPartyVendorProduct`, request);
  }

  public deleteVendorProduct(request: VendorProductDeleteRequest): ServiceResult<undefined> {
    return this.post(`${this.persistUrl}/DeleteThirdPartyVendorProduct`, request);
  }

  public getThirdPartyUnitOfMeasures(customerId?: string, vendorId?: string): ServiceResult<UnitOfMeasures[]> {
    return this.get(
      `${this.persistUrl}/GetThirdPartyUnitOfMeasures?customerId=${customerId}&thirdPartyVendorId=${vendorId}`
    );
  }

  public static getInstance(): VendorProductService {
    if (!this.classInstance) {
      this.classInstance = new VendorProductService();
    }
    return this.classInstance;
  }
}

export default VendorProductService;
