import { Box, Link, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { OrderConfirmationNotification, UserCustomer } from 'common/src/api/index';
import { AppRoutes, RouteName } from 'common/src/models/index';
import { RootState, useAppSelector } from 'common/src/store/index';
import { FC } from 'react';
import { StyledEllipsingTextContainer } from '../../../../_shared/components/shared-styled-components.styles';
import { SideBarActionPlacement } from '../../../../finances/components/_shared/SideBarCard/SideBarCard';
import CommunicationsEmailBody from './CommunicationsEmailBody/CommunicationsEmailBody';
import CommunicationsEmailCard from './CommunicationsEmailCard';

interface CommunicationsNotificationsManagementProps {
  selectedCustomer: UserCustomer;
  contactEmail: string;
}

const CommunicationsNotificationsManagement: FC<CommunicationsNotificationsManagementProps> = (
  props: CommunicationsNotificationsManagementProps
) => {
  const orderConfirmationSubscriptions: OrderConfirmationNotification[] | undefined = useAppSelector(
    (s: RootState) => s.orderConfirmation.orderConfirmationNotifications
  );

  return (
    <>
      {props.contactEmail ? (
        <Box
          sx={{
            width: 'fit-content',
            maxWidth: '100%',
            backgroundColor: (theme: Theme) => theme.palette.coolGrey[100],
            p: 2,
            borderRadius: '4px',
          }}
        >
          <Stack sx={{ width: '100%', gap: { xs: 2, xl: 1 } }}>
            <StyledEllipsingTextContainer $lines={2}>
              <Typography
                variant='responsiveParagraphSmall'
                sx={{
                  color: (theme: Theme) => theme.palette.coolGrey[700],
                }}
                data-testid='communications-email-text-content'
              >
                Receive email confirmations for all orders you submit to:
                <Typography
                  component='span'
                  sx={{
                    display: 'inline',
                    ml: 0.5,
                    fontSize: 'inherit',
                    lineHeight: 'inherit',
                    fontWeight: (theme) => theme.typography.paragraphBold.fontWeight,
                    color: (theme: Theme) => theme.palette.coolGrey[700],
                  }}
                  data-testid='communications-contact-email'
                >
                  {props.contactEmail}
                </Typography>
              </Typography>
            </StyledEllipsingTextContainer>
            <Typography variant='responsiveParagraphSmall' sx={{ color: (theme: Theme) => theme.palette.primary.main }}>
              Need to update? Modify in{' '}
              <Link href={AppRoutes[RouteName.UserProfile].Path} data-testid='communications-account-profile-link'>
                <Typography variant='responsiveParagraphSmall'>Account Profile.</Typography>
              </Link>
            </Typography>
          </Stack>
        </Box>
      ) : (
        <Box sx={{ p: 1 }} />
      )}
      <CommunicationsEmailCard
        sx={{
          overflowY: 'auto',
          width: 'fit-content',
          maxWidth: '100%',
        }}
        title='The following users will receive all order confirmations for this location'
        actionPlacement={SideBarActionPlacement.header}
        actionSx={{ flex: 'auto 1 0', display: 'flex', justifyContent: 'space-between', pl: 0.5 }}
        canHide={true}
        actionLabelTestId='communications-email-list-action-label'
        containerWidth={false}
        collapsedByDefault={true}
        titleTestId='communications-email-list-title'
        titleSx={{
          textTransform: 'none',
          typography: 'responsiveParagraphSmallBold',
          color: (theme: Theme) => theme.palette.coolGrey[600],
          fontSize: { xs: 14, xl: 12 },
        }}
      >
        <Stack sx={{ gap: 1, maxHeight: { xs: 'unset' } }}>
          <CommunicationsEmailBody
            selectedCustomer={props.selectedCustomer}
            notificationSubscriptions={orderConfirmationSubscriptions}
            defaultUserEmail={props.contactEmail}
          />
        </Stack>
      </CommunicationsEmailCard>
    </>
  );
};

export default CommunicationsNotificationsManagement;
