import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  VendorProduct,
  VendorProductDeleteRequest,
  VendorProductListRequest,
  VendorProductRequest,
} from '../../api/models/vendor-products.models';
import VendorProductService from '../../api/services/vendor-product.service';
import { useAppInsightsLogger } from '../../logging/index';
import { Resolution, UserActivityAction, UserActivityActionSummary, UserActivityPageName } from '../../models';
import { globalSlice, logUserActivity } from '../common/index';
import { resetProductListState } from '../product-list-management';
import { productListSlice } from '../product-list-management/product-list.slice';
import { AppDispatch, AppThunk, RootState } from '../store';
import { getVendor } from './vendor-management.thunks';
import { vendorProductSlice } from './vendor-product.slice';

const appInsightsLogger = useAppInsightsLogger();
const vendorProductService = VendorProductService.getInstance();

export const getVendorProductList =
  (request: VendorProductListRequest, forceRefetch?: boolean): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const priorRequest = getState().vendorProductSlice.vendorProductListRequest;
      if (!forceRefetch && JSON.stringify(request) === JSON.stringify(priorRequest)) {
        return;
      }

      if (!getState().vendorProductSlice.isLoadingVendorProductList) {
        dispatch(vendorProductSlice.actions.setIsLoadingVendorList(true));
      }

      dispatch(vendorProductSlice.actions.setVendorProductListRequest(request));

      const { data } = await vendorProductService.getVendorProductList(request);
      if (data.IsSuccess) {
        dispatch(vendorProductSlice.actions.setVendorProductListAdapter(data.ResultObject));
      } else {
        dispatch(vendorProductSlice.actions.resetVendorList());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      console.error(error);
    } finally {
      dispatch(vendorProductSlice.actions.setIsLoadingVendorList(false));
    }
  };

export const getVendorProduct =
  (request: VendorProductRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const product = getState().vendorProductSlice.product;
      if (product && product.ThirdPartyVendorProductId === request.thirdPartyVendorProductId) {
        return;
      }

      if (!getState().vendorProductSlice.isLoadingVendorProductList) {
        dispatch(vendorProductSlice.actions.setIsLoadingVendorProduct(true));
      }
      const { data } = await vendorProductService.getVendorProduct(request);
      if (data.IsSuccess) {
        dispatch(vendorProductSlice.actions.setProduct(data.ResultObject));

        if (data.ResultObject.ThirdPartyVendorId != getState().vendorManagement.vendor?.ThirdPartyVendorId) {
          await dispatch(getVendor(request.customerId, data.ResultObject.ThirdPartyVendorId));
        }
      } else {
        dispatch(vendorProductSlice.actions.resetProduct());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }

      dispatch(vendorProductSlice.actions.setIsLoadingVendorProduct(false));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      console.error(error);
    }
  };

export const createOrUpdateProduct =
  (
    request: VendorProduct,
    resolution: Resolution,
    successCallback?: (thirdPartyVendorProductId: string) => void | Promise<void>,
    errorCallback?: (errors: string[]) => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().vendorProductSlice.isCreateOrUpdateProductLoading) {
        dispatch(vendorProductSlice.actions.setIsCreateOrUpdateProductLoading(true));
      }

      let response;
      if (request.ThirdPartyVendorProductId) {
        response = await vendorProductService.updateVendorProduct(request);
      } else {
        response = await vendorProductService.createVendorProduct(request);
      }

      const { data } = response;

      if (data.IsSuccess) {
        dispatch(vendorProductSlice.actions.setIsCreateOrUpdateProductLoading(false));
        dispatch(vendorProductSlice.actions.setProduct(data.ResultObject));
        dispatch(productListSlice.actions.updateVendorProduct(data.ResultObject));

        successCallback?.(response?.data.ResultObject.ThirdPartyVendorProductId);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(vendorProductSlice.actions.setIsCreateOrUpdateProductLoading(false));
    }
  };

export const deleteProduct =
  (
    customerId: string,
    thirdPartyVendorProductId: string,
    resolution: Resolution,
    successCallback?: () => void,
    errorCallback?: (errors: string[]) => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const request: VendorProductDeleteRequest = {
        CustomerId: customerId,
        ThirdPartyVendorProductId: thirdPartyVendorProductId,
      };

      if (!getState().vendorProductSlice.isDeleteProductLoading) {
        dispatch(vendorProductSlice.actions.setIsDeleteProductLoading(true));
      }

      dispatch(
        logUserActivity({
          action: UserActivityAction.DeleteVendorProduct,
          pageName: UserActivityPageName.VendorProductDetails,
          actionSummary: UserActivityActionSummary.DeletedVendorProduct,
          resolution: resolution,
        })
      );

      const { data } = await vendorProductService.deleteVendorProduct(request);

      if (data.IsSuccess) {
        if (thirdPartyVendorProductId)
          dispatch(productListSlice.actions.removeVendorProduct(thirdPartyVendorProductId));

        successCallback?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      console.error(error);
    } finally {
      dispatch(vendorProductSlice.actions.setIsDeleteProductLoading(false));
    }
  };

export const getVendorProductUOM =
  (customerId?: string, vendorId?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await vendorProductService.getThirdPartyUnitOfMeasures(customerId, vendorId);
      if (data.IsSuccess) {
        dispatch(vendorProductSlice.actions.setUOMs(data.ResultObject));
      } else {
        dispatch(vendorProductSlice.actions.resetUOMs());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      console.error(error);
    }
  };

export const updateVendorProductIsOnList =
  (productListHeaderIds: string[], id: string, isOnList: boolean): AppThunk =>
  async (dispatch, getState) => {
    const priorRequest = getState().vendorProductSlice.vendorProductListRequest;

    if (!priorRequest?.ProductListHeaderId) return;

    if (productListHeaderIds.includes(priorRequest.ProductListHeaderId)) {
      dispatch(vendorProductSlice.actions.setVendorProductIsOnList({ id, isOnList }));
      dispatch(resetProductListState());
    }
  };
