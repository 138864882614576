import { InventorySnapshotHeader, InventorySnapshotLogActionType } from '../../api';
import { InventoryEntryHeader } from '../../api/models/inventory.models';
import InventoryEntryHeaderService from '../../api/services/inventory-entry-header.service';
import InventorySnapshotHeaderService from '../../api/services/inventory-snapshot-header.service';
import { useAppInsightsLogger } from '../../logging';
import { globalSlice } from '../common';
import { AppDispatch, AppThunk, RootState } from '../store';
import { InventoryManagementGridParameters, inventoryManagementSlice } from './inventory-management.slice';

const appInsightsLogger = useAppInsightsLogger();
const inventoryEntryHeaderService = InventoryEntryHeaderService.getInstance();
const inventorySnapshotHeaderService = InventorySnapshotHeaderService.getInstance();

//
export const getInventoryManagementData =
  (request: InventoryManagementGridParameters): AppThunk =>
  async (dispatch, getState) => {
    const currentParams = getState().inventoryManagement.gridParameters;

    if (currentParams?.customerId != request.customerId) {
      dispatch(getInventoryManagementHeaders(request.customerId));
      dispatch(getInventorySnapshotHeadersInReview(request.customerId));
    }
    if (JSON.stringify({ ...currentParams }) !== JSON.stringify({ ...request })) {
      dispatch(getInventorySnapshotHeadersCompleted(request.customerId, request.startDate, request.endDate));
    }

    dispatch(inventoryManagementSlice.actions.setGridParameters(request));
  };

export const getInventorySnapshotMergeDetails =
  (snapshotId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(inventoryManagementSlice.actions.setIsLoadingInventorySnapshotLogs(true));

    try {
      const { data } = await inventorySnapshotHeaderService.getInventorySnapshotLogs({
        InventorySnapshotHeaderId: snapshotId,
        ActionType: InventorySnapshotLogActionType.MergeInventory,
      });
      if (data.IsSuccess) {
        dispatch(inventoryManagementSlice.actions.setInventorySnapshotLogs(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }
    dispatch(inventoryManagementSlice.actions.setIsLoadingInventorySnapshotLogs(false));
  };

export const getInventoryManagementHeaders =
  (customerId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().inventoryManagement.isLoadingInventoryInProgress) {
        dispatch(inventoryManagementSlice.actions.setIsLoadingInventoryInProgress(true));
      }

      //const data = inventoryHeaderMock;
      const { data } = await inventoryEntryHeaderService.getInventoryEntryHeaders({ CustomerId: customerId });

      if (data.IsSuccess) {
        dispatch(inventoryManagementSlice.actions.setInventoryInProgress(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        //severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(inventoryManagementSlice.actions.setIsLoadingInventoryInProgress(false));
    }
  };

export const createInventoryEntryHeader =
  (customerId?: string, onSuccess?: (header: InventoryEntryHeader) => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (!customerId) return;
    try {
      const req = { CustomerId: customerId, ParListHeaderId: undefined };
      dispatch(inventoryManagementSlice.actions.setIsInventoryEntryHeaderSubmitting(true));
      const { data } = await inventoryEntryHeaderService.createInventoryEntryHeader(req);
      if (data.IsSuccess) {
        onSuccess?.(data.ResultObject);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
      dispatch(getInventoryManagementHeaders(customerId));
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        //severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(inventoryManagementSlice.actions.setIsInventoryEntryHeaderSubmitting(false));
    }
  };

export const getInventorySnapshotHeadersInReview =
  (customerId?: string, startDate?: Date | string, endDate?: Date | string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!customerId) return;
    try {
      if (!getState().inventoryManagement.isLoadingInventoryInReview)
        dispatch(inventoryManagementSlice.actions.setIsLoadingInventoryInReview(true));

      const req = { CustomerId: customerId, StartDate: startDate, EndDate: endDate };

      const { data } = await inventorySnapshotHeaderService.getInventorySnapshotHeadersInReview(req);

      if (data.IsSuccess) {
        dispatch(inventoryManagementSlice.actions.setInventoryInReview(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } finally {
      dispatch(inventoryManagementSlice.actions.setIsLoadingInventoryInReview(false));
    }
  };

export const getRecentInventorySnapshotHeadersCompleted =
  (customerId?: string, onSuccess?: (data: InventorySnapshotHeader[]) => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    const NUM_DAYS_AGO = 90;
    const date = new Date();
    date.setDate(date.getDate() - NUM_DAYS_AGO);
    dispatch(getInventorySnapshotHeadersCompleted(customerId, date.toDateString(), undefined, onSuccess));
  };

export const getInventorySnapshotHeadersCompleted =
  (
    customerId?: string,
    startDate?: string,
    endDate?: string,
    onSuccess?: (data: InventorySnapshotHeader[]) => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!customerId) return;
    try {
      if (!getState().inventoryManagement.isLoadingInventoryCompleted)
        dispatch(inventoryManagementSlice.actions.setIsLoadingInventoryCompleted(true));

      const req = { CustomerId: customerId, StartDate: startDate, EndDate: endDate };

      const { data } = await inventorySnapshotHeaderService.getInventorySnapshotHeadersCompleted(req);

      if (data.IsSuccess) {
        dispatch(inventoryManagementSlice.actions.setInventoryCompleted(data.ResultObject));
        onSuccess?.(data.ResultObject);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } finally {
      dispatch(inventoryManagementSlice.actions.setIsLoadingInventoryCompleted(false));
    }
  };
