import { EntityState, PayloadAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { LruObjectCache, createCachedSelector } from 're-reselect';
import {
  CustomerProductPriceRequest,
  CustomerProductPriceResult,
  InventoryCustomerProduct,
  InventoryEntryHeader,
  InventoryEntryProduct,
  ProductListHeaderCustom,
  ProductListProduct,
  ProductListSortOption,
  ResetInventoryCustomerProductUnitOfMeasureRequest,
  ResetInventoryCustomerProductUnitOfMeasuresRequest,
  UnitOfMeasureOrderQuantity,
  UpdateCustomProductRequest,
} from '../../api';
import {
  GetInventoryEntrySearchRequest,
  GetInventoryEntrySearchResultDetail,
} from '../../api/models/inventory-entry-search.models';
import {
  appendPossessive,
  normalizeKey,
  normalizeProductKey,
  updateProductCustomAttributesFromPayload,
} from '../../helpers';
import { getNormalizedProductListGridData } from '../../helpers/general/product-list';
import { InventoryDetailTransfer } from '../../models/inventory.models';
import {
  InventoryEntryListGridData,
  ProductListCategoryCustom,
  ProductListCategoryProduct,
  ProductListGridData,
} from '../../models/product-list.models';
import { EntityPage, EntityPageLoading, PagedEntityState, createPagedEntityAdapter } from '../paged-entity-adapter';
import { RootState } from '../store';

//Adapter
const productsAdapter = createEntityAdapter<ProductListProduct>({
  selectId: (product: ProductListProduct) => normalizeProductKey(product.Id),
});

const categoriesAdapter = createEntityAdapter<ProductListCategoryCustom>({
  selectId: (category: ProductListCategoryCustom) => category.Id,
});

const categoryProductsAdapter = createPagedEntityAdapter<ProductListCategoryProduct>({
  selectId: (categoryProduct: ProductListCategoryProduct) => categoryProduct.Id,
});

const productListHeadersAdapter = createEntityAdapter<ProductListHeaderCustom>({
  selectId: (productListHeader) => productListHeader.ProductListHeaderId,
});

const inventoryEntryProductAdapter = createEntityAdapter<InventoryEntryProduct>({
  selectId: (iep) => normalizeProductKey(iep.ThirdPartyVendorProductId || iep.ProductKey),
});

// States
interface InventoryEntryState {
  isUpdatingInventoryProduct: boolean;
  inventoryEntryProducts: EntityState<InventoryEntryProduct>;

  isSubmittingInventoryEntryHeader: boolean;

  isLoadingInventoryEntryHeader: boolean;
  inventoryEntryHeader?: InventoryEntryHeader;

  isProductListHeadersLoading: boolean;
  productListHeaders: EntityState<ProductListHeaderCustom>;

  sortByOptions: ProductListSortOption[];
  isLoadingSortByOptions: boolean;

  isLoadingInventoryEntryDetails: boolean;

  categories: EntityState<ProductListCategoryCustom>;
  categoryProducts: PagedEntityState<ProductListCategoryProduct>;
  products: EntityState<ProductListProduct>;

  apiRequest?: GetInventoryEntrySearchRequest;
  isLoadingProductSearchResult: boolean;
  productSearchResult?: GetInventoryEntrySearchResultDetail;

  //isShowCompletedToggled?: boolean; // todo move to history state
}

const initialState: InventoryEntryState = {
  isSubmittingInventoryEntryHeader: false,
  isUpdatingInventoryProduct: false,
  inventoryEntryProducts: inventoryEntryProductAdapter.getInitialState(),

  isLoadingInventoryEntryHeader: false,
  inventoryEntryHeader: undefined,

  isProductListHeadersLoading: true,
  productListHeaders: productListHeadersAdapter.getInitialState(),

  isLoadingSortByOptions: true,
  sortByOptions: [],

  isLoadingInventoryEntryDetails: true,

  apiRequest: undefined,
  isLoadingProductSearchResult: true,
  productSearchResult: undefined,

  categories: categoriesAdapter.getInitialState(),
  categoryProducts: categoryProductsAdapter.getInitialState(),
  products: productsAdapter.getInitialState(),
};

// Reducers
export const inventoryEntrySlice = createSlice({
  name: 'inventoryEntry',
  initialState: initialState,
  reducers: {
    setIsUpdatingInventoryProduct: (state: InventoryEntryState, action: PayloadAction<boolean>) => {
      state.isUpdatingInventoryProduct = action.payload;
    },
    setInventoryEntryProduct: (state: InventoryEntryState, action: PayloadAction<InventoryEntryProduct>) => {
      inventoryEntryProductAdapter.setOne(state.inventoryEntryProducts, action.payload);
    },

    setIsSubmittingInventoryEntryHeader: (state: InventoryEntryState, action: PayloadAction<boolean>) => {
      state.isSubmittingInventoryEntryHeader = action.payload;
    },
    setIsLoadingInventoryEntryHeader: (state: InventoryEntryState, action: PayloadAction<boolean>) => {
      state.isLoadingInventoryEntryHeader = action.payload;
    },
    setInventoryEntryHeader: (state: InventoryEntryState, action: PayloadAction<InventoryEntryHeader>) => {
      state.inventoryEntryHeader = action.payload;
    },
    resetInventoryEntryHeader: (state: InventoryEntryState) => {
      state.inventoryEntryHeader = initialState.inventoryEntryHeader;
    },
    setIsProductListHeadersLoading: (state: InventoryEntryState, action: PayloadAction<boolean>) => {
      state.isProductListHeadersLoading = action.payload;
    },
    resetProductListHeadersLoading: (state: InventoryEntryState) => {
      state.isProductListHeadersLoading = initialState.isProductListHeadersLoading;
    },
    setProductListHeaders: (state: InventoryEntryState, action: PayloadAction<ProductListHeaderCustom[]>) => {
      productListHeadersAdapter.setAll(state.productListHeaders, action.payload);
    },
    resetProductListHeaders: (state: InventoryEntryState) => {
      state.productListHeaders = initialState.productListHeaders;
    },
    setSortByOptions: (state: InventoryEntryState, action: PayloadAction<ProductListSortOption[]>) => {
      state.sortByOptions = action.payload;
    },
    setIsLoadingSortByOptions: (state: InventoryEntryState, action: PayloadAction<boolean>) => {
      state.isLoadingSortByOptions = action.payload;
    },
    setIsLoadingInventoryEntryDetails: (state: InventoryEntryState, action: PayloadAction<boolean>) => {
      state.isLoadingInventoryEntryDetails = action.payload;
    },
    updateInventoryEntryDetail: (state: InventoryEntryState, action: PayloadAction<InventoryDetailTransfer>) => {
      const product = state.products.entities[normalizeProductKey(action.payload.productId)];
      if (product) {
        product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
          if (uom.UnitOfMeasure === action.payload.uom) {
            uom.QuantityFull = action.payload.quantityFull;
            uom.QuantityPartial = action.payload.quantityPartial;
          }
        });
      }
    },

    setIsInventoryEntryDetailUpdating: (state: InventoryEntryState, action: PayloadAction<boolean>) => {
      state.isLoadingInventoryEntryDetails = action.payload;
    },
    resetSearchProductListInventoryEntry: (state: InventoryEntryState) => {
      state.categoryProducts = initialState.categoryProducts;
      state.categories = initialState.categories;
      state.products = initialState.products;
      state.productSearchResult = initialState.productSearchResult;
      state.apiRequest = initialState.apiRequest;
      state.inventoryEntryProducts = initialState.inventoryEntryProducts;
    },
    setApiRequest: (state: InventoryEntryState, action: PayloadAction<GetInventoryEntrySearchRequest | undefined>) => {
      state.apiRequest = action.payload;
    },
    setIsLoadingProductSearchResult: (state: InventoryEntryState, action: PayloadAction<boolean>) => {
      state.isLoadingProductSearchResult = action.payload;
    },
    searchProductListParMaintenance: (
      state: InventoryEntryState,
      action: PayloadAction<{
        request: GetInventoryEntrySearchRequest;
        result: GetInventoryEntrySearchResultDetail;
        previousPage?: ProductListGridData[];
      }>
    ) => {
      const { request, result, previousPage } = action.payload;

      state.apiRequest = request;

      const data = getNormalizedProductListGridData(result.ProductListCategories, false, previousPage, true);
      categoriesAdapter.addMany(state.categories, data.categories);
      categoryProductsAdapter.setLoadedPage(state.categoryProducts, {
        pageIndex: result.pageIndex,
        entities: data.categoryProducts,
      });
      productsAdapter.addMany(state.products, data.products);

      const searchResult = { ...result, InventoryEntryDetails: [], ProductListCategories: [] };
      state.productSearchResult = searchResult;

      inventoryEntryProductAdapter.addMany(state.inventoryEntryProducts, action.payload.result.InventoryEntryProducts);
    },
    setPageLoading: (state: InventoryEntryState, action: PayloadAction<EntityPageLoading>) => {
      categoryProductsAdapter.setLoadingPage(state.categoryProducts, action.payload);
    },
    updateCategoryIsCollapsed: (
      state: InventoryEntryState,
      action: PayloadAction<{ isCollapsed: boolean; categoryId: string }>
    ) => {
      const category = state.categories.entities[action.payload.categoryId];
      if (category) category.IsCollapsed = action.payload.isCollapsed;
    },
    updateAllCategoryIsCollapsed: (state: InventoryEntryState, action: PayloadAction<boolean>) => {
      const categories = Object.values(state.categories.entities);
      categories.map((c) => {
        if (c) c.IsCollapsed = action.payload;
        return c;
      });
    },
    resetCategories: (state: InventoryEntryState) => {
      state.categories = categoriesAdapter.getInitialState();
    },
    setProductPrices: (state: InventoryEntryState, action: PayloadAction<CustomerProductPriceResult[]>) => {
      action.payload.forEach((priceResult: CustomerProductPriceResult) => {
        if (priceResult.ProductKey) {
          const product = state.products.entities[normalizeProductKey(priceResult.ProductKey)];
          if (product) {
            product.PriceLoaded = true;
            product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
              if (uom.UnitOfMeasure === priceResult.UnitOfMeasureType) {
                uom.Price = priceResult.Price;
                if (priceResult.ProductAverageWeight > 0) {
                  uom.ProductAverageWeight = priceResult.ProductAverageWeight;
                }
              }
            });
          }
        }
      });
    },
    setProductPricesReload: (state: InventoryEntryState, action: PayloadAction<CustomerProductPriceRequest[]>) => {
      action.payload.forEach((priceResult: CustomerProductPriceRequest) => {
        if (priceResult.ProductKey) {
          const product = state.products.entities[normalizeProductKey(priceResult.ProductKey)];
          if (product) {
            product.PriceLoaded = false;
          }
        }
      });
    },
    resetProductDetails: (state: InventoryEntryState) => {
      state.products.ids.forEach((id) => {
        const product = state.products.entities[id];
        if (product) {
          product.UnitOfMeasureOrderQuantities.forEach((uom) => {
            uom.QuantityFull = undefined;
            uom.QuantityPartial = undefined;
          });
        }
      });
    },
    setProductCustomPackSize: (state: InventoryEntryState, action: PayloadAction<InventoryCustomerProduct>) => {
      const product = state.products.entities[normalizeProductKey(action.payload.ProductKey)];
      if (product) {
        const uomToUpdate = product.UnitOfMeasureOrderQuantities.find(
          (uom) => uom.UnitOfMeasure === action.payload.UnitOfMeasureType
        );
        if (uomToUpdate) {
          uomToUpdate.CustomUnitsPerFull = action.payload.CustomUnitsPerFull;
          uomToUpdate.CustomUnitsPerFullDescription = action.payload.CustomUnitsPerFullDescription;
          uomToUpdate.CustomPackSize = action.payload.CustomPackSize;
        }
      }
    },
    resetAllCustomPackSizes: (
      state: InventoryEntryState,
      action: PayloadAction<ResetInventoryCustomerProductUnitOfMeasuresRequest>
    ) => {
      const products = Object.values(state.products.entities);
      products.forEach((product) => {
        if (product) {
          product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
            uom.CustomPackSize = uom.PackSize;
            uom.CustomUnitsPerFull = undefined;
            uom.CustomUnitsPerFullDescription = '';
          });
        }
      });
    },
    resetProductCustomPackSize: (
      state: InventoryEntryState,
      action: PayloadAction<ResetInventoryCustomerProductUnitOfMeasureRequest>
    ) => {
      const product = state.products.entities[normalizeProductKey(action.payload.ProductKey)];
      if (product) {
        product.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
          uom.CustomPackSize = uom.PackSize;
          uom.CustomUnitsPerFull = undefined;
          uom.CustomUnitsPerFullDescription = '';
        });
      }
    },
    updateProductCustomAttributes: (state: InventoryEntryState, action: PayloadAction<UpdateCustomProductRequest>) => {
      const product = state.products.entities[normalizeProductKey(action.payload.ProductKey)];
      if (!product) return;

      updateProductCustomAttributesFromPayload(product, action.payload);

      const notificationProduct = state.products.entities[normalizeProductKey(action.payload.ProductKey)];
      if (!notificationProduct) return;

      updateProductCustomAttributesFromPayload(notificationProduct, action.payload);
    },
  },
});

// Selectors

const getState = (state: RootState): RootState => state;
const getIndex = (state: RootState, index: number): number => index;
const getId = (state: RootState, id: string): string => id;

export const { selectById: selectInventoryEntryProductNoteById } = inventoryEntryProductAdapter.getSelectors<
  EntityState<InventoryEntryProduct>
>((state: EntityState<InventoryEntryProduct>) => state);

export const { selectAll: selectAllInventoryEntryCategories, selectById: selectInventoryEntryCategoriesById } =
  categoriesAdapter.getSelectors<EntityState<ProductListCategoryCustom>>(
    (state: EntityState<ProductListCategoryCustom>) => state
  );

export const {
  selectAll: selectAllInventoryEntryCatalogProducts,
  selectById: selectInventoryEntryCatalogProductById,
  selectIsLoading: selectInventoryEntryProductListIsLoading,
  selectByPage: selectInventoryEntryCatalogProductsByPage,
} = categoryProductsAdapter.getSelectors<PagedEntityState<ProductListCategoryProduct>>(
  (state: PagedEntityState<ProductListCategoryProduct>) => state
);

export const { selectAll: selectAllInventoryEntryProducts, selectById: selectInventoryEntryProductById } =
  productsAdapter.getSelectors<EntityState<ProductListProduct>>((state: EntityState<ProductListProduct>) => state);

export const {
  selectAll: selectAllInventoryEntryProductListHeaders,
  selectById: selectInventoryEntryProductListHeadersById,
} = productListHeadersAdapter.getSelectors<EntityState<ProductListHeaderCustom>>(
  (state: EntityState<ProductListHeaderCustom>) => state
);

// Custom Selectors
export const selectInventoryEntryGridDataById = createCachedSelector(
  (state: RootState) => state.inventoryEntry.categories,
  (state: RootState) => state.inventoryEntry.categoryProducts,
  (state: RootState) => state.inventoryEntry.products,
  (state: RootState) => state.inventoryEntry.inventoryEntryProducts,

  (_state: RootState, id: string) => id,
  (categories, categoryProducts, products, inventoryEntryProducts, id) => {
    const normalizedId = normalizeKey(id);

    const category = selectInventoryEntryCategoriesById(categories, normalizedId);
    if (category)
      return {
        category: category,
        categoryProduct: undefined,
        product: undefined,
        inventoryEntryDetails: undefined,
      } as InventoryEntryListGridData;

    const categoryProduct = selectInventoryEntryCatalogProductById(categoryProducts, normalizedId);
    if (categoryProduct) {
      const category = selectInventoryEntryCategoriesById(categories, categoryProduct.CategoryId);
      const product = selectInventoryEntryProductById(products, normalizeProductKey(categoryProduct.ProductId));
      const inventoryEntryProduct = selectInventoryEntryProductNoteById(
        inventoryEntryProducts,
        categoryProduct.ProductId
      );
      if (product) {
        return { category, categoryProduct, product, inventoryEntryProduct } as InventoryEntryListGridData;
      }
    }

    return undefined;
  }
)({
  keySelector: (_state: RootState, categoryProductId: string) => categoryProductId,
  cacheObject: new LruObjectCache({ cacheSize: 100 }),
});

export const selectAllInventoryEntryIds = createSelector(
  (state: RootState) => state.inventoryEntry.categories,
  (state: RootState) => state.inventoryEntry.categoryProducts,
  (
    categories,
    categoryProducts
  ): {
    [pageIndex: number]: EntityPage;
  } => {
    const categorySet = new Set<string>();

    const pages: {
      [pageIndex: number]: EntityPage;
    } = {};

    for (let i = 0; i <= categoryProducts.totalPageCount; i++) {
      const page = categoryProducts.pages?.[i];
      if (!page) continue; // Loading pages are not included in the `totalPageCount`. Check for loading page

      if (page.isLoading) {
        pages[i] = { ...page };
      } else {
        const ids: string[] = [];
        page.ids.forEach((id) => {
          const categoryProduct = selectInventoryEntryCatalogProductById(categoryProducts, id);
          if (!categoryProduct) return;
          const category = selectInventoryEntryCategoriesById(categories, categoryProduct?.CategoryId);
          if (!category) return;

          if (!category.IsCollapsed) {
            ids.push(id);
          } else if (category.IsCollapsed && !categorySet.has(category.Id)) {
            ids.push(category.Id);
            categorySet.add(category.Id);
          }
        });
        pages[i] = { ...page, ids };
      }
    }
    return pages;
  }
);

export const selectInventoryEntryProductListGridDataByPage = createSelector([getState, getIndex], (state, index) => {
  const result: InventoryEntryListGridData[] = [];
  const categoryProducts = selectInventoryEntryCatalogProductsByPage(state.inventoryEntry.categoryProducts, index);
  categoryProducts.forEach((cp) => {
    if (cp) {
      const data = selectInventoryEntryGridDataById(state, cp.Id);
      if (data) result.push(data);
    }
  });
  return result;
});

export const selectInventoryEntryProductsByPage = createSelector([getState, getIndex], (state, pageIndex) => {
  const categoryProducts = selectInventoryEntryCatalogProductsByPage(state.inventoryEntry.categoryProducts, pageIndex);
  const products = [] as ProductListProduct[];
  categoryProducts.forEach((cp) => {
    const data = selectInventoryEntryGridDataById(state, cp.Id);
    if (data?.product) products.push(data.product);
  });
  return products;
});

export const selectInventoryEntrySelectedProductListHeader = createSelector([getState, getId], (state, id) => {
  return selectInventoryEntryProductListHeadersById(state.inventoryEntry.productListHeaders, id);
});

export const selectInventoryEntryProductsMissingExtendedPrice = createSelector(
  (state: RootState) => selectAllInventoryEntryProducts(state.orderEntry.products),
  (products) =>
    products.flatMap((p) =>
      p.UnitOfMeasureOrderQuantities.map((uom) => ({ product: p, uom })).filter(
        (data) => data.uom.Quantity && !data.uom.ExtendedPrice
      )
    )
);

export const selectAllInventoryEntryCategoriesCollapsed = createSelector(
  (state: RootState) => state.inventoryEntry.categories,
  (state) => {
    const categories = selectAllInventoryEntryCategories(state);
    if (categories.length === 0) return false;
    return categories.filter((c) => !c.IsCollapsed).length === 0;
  }
);

export const selectInventoryEntryDisplay = createSelector(
  (state: RootState) => state.customer.selectedCustomer?.UserId,
  (_state: RootState, header: InventoryEntryHeader | undefined) => header,
  (userId, header) => {
    if (!header) return 'View inventory';
    return userId == header.UserId ? 'My inventory' : `${appendPossessive(header.UserDisplay)} inventory`;
  }
);

export const selectProductById = (state: RootState, productId?: string) =>
  selectInventoryEntryProductById(state.inventoryEntry.products, normalizeProductKey(productId));
