import { Icon } from '@mui/material';
import { getCustomerDeliveryDates, RootState, toLocalDateString, useAppDispatch, useAppSelector } from 'common';
import React, { FC, RefObject, useEffect, useState } from 'react';
import { CfDatePicker } from '../../../../../cf-ui/date-pickers/DatePicker/CfDatePicker';
import { DeviceType, useBreakpoint } from '../../../../../hooks/useBreakpoint';

export type OrderDeliveryDateCalendarProps = {
  portalId?: string;
  date?: Date | string | undefined;
  iconRef?: RefObject<HTMLSpanElement>;
  onChange?: (calendarDate: Date) => unknown;
  onIconKeyPress?: (event: React.KeyboardEvent) => void;
  testId?: string;
};

export const OrderDeliveryDateCalendar: FC<OrderDeliveryDateCalendarProps> = (
  props: OrderDeliveryDateCalendarProps
) => {
  // hooks
  const dispatch = useAppDispatch();
  const { deviceType } = useBreakpoint();

  // selectors
  const availableDeliveryDates = useAppSelector((x: RootState) => x.customer.deliveryDates);

  // state
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [availableDates, setAvailableDates] = useState<Date[]>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const iconRef = props.iconRef;

  useEffect(() => {
    // Convert date property to date object
    const d = props.date ? new Date(props.date) : undefined;

    // If value is unchanged then exit
    if (selectedDate?.valueOf() === d?.valueOf()) return;

    // If value has been updated, set our state.
    setSelectedDate(d);
  }, [props?.date]);

  useEffect(() => {
    const availableDates = availableDeliveryDates.map((date: string) => {
      return getActualDate(date);
    });
    if (selectedDate) availableDates.push(selectedDate);

    setAvailableDates(availableDates);
  }, [availableDeliveryDates]);

  const getActualDate = (date: string) => {
    const year = date.substring(0, 4);
    const month = date.substring(5, 7);
    const day = date.substring(8, 10);
    return new Date(+year, +month - 1, +day, 0, 0, 0);
  };

  const onCalendarOpen = () => {
    dispatch(getCustomerDeliveryDates());
  };

  const handleCalendarDateChange = (calendarDate: Date | null) => {
    setIsOpen(false);
    if (!calendarDate) return;

    const dateString = toLocalDateString(calendarDate);

    props.onChange?.(new Date(dateString));
  };

  const handleIconClick = () => {
    setIsOpen(!isOpen);
    onCalendarOpen();
  };

  const handleIconKeyPress = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === 'Enter') {
      setIsOpen(!isOpen);
      onCalendarOpen();
    }
    props.onIconKeyPress?.(e);
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  const CustomInput = (props: any, _ref: any) => {
    return (
      <Icon
        {...props}
        className='fa-calendar'
        data-testid='summary-calendar-icon'
        ref={iconRef}
        sx={{
          fontSize: deviceType === DeviceType.Desktop ? 14 : 16,
          mx: 2,
          lineHeight: deviceType === DeviceType.Desktop ? '18px !important' : '20px !important',
          cursor: 'pointer',
          color: (theme) => theme.palette.common.black,
        }}
        tabIndex={0}
        onFocus={(e: React.FocusEvent<HTMLSpanElement>) => e.stopPropagation()}
        onClick={handleIconClick}
        onKeyPress={handleIconKeyPress}
      />
    );
  };

  return (
    <>
      <CfDatePicker
        open={isOpen}
        id='calendar'
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          e.key === 'Escape' && setIsOpen(false);
          e.key === 'Tab' && setIsOpen(false);
          e.key === 'Tab' && props.onIconKeyPress?.(e);
          e.key === 'Enter' && setIsOpen(true);
        }}
        onCalendarOpen={onCalendarOpen}
        onChange={handleCalendarDateChange}
        onClickOutside={() => setIsOpen(false)}
        selected={selectedDate}
        customInput={React.createElement(React.forwardRef(CustomInput))}
        includeDates={availableDates?.map((each) => new Date(each)) || []}
        highlightDates={availableDates?.map((each) => new Date(each)) || []}
        portalId={props.portalId}
        testId={props.testId}
      />
    </>
  );
};
