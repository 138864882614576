import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReorderResult } from '../../api/models/reorder.models';

// State
interface ReorderState {
  isReorderLoading: boolean;
  reorderResult?: ReorderResult;
  isProductsNotFoundDialogLoading: boolean;
  showProductsNotFoundDialog: boolean;
  showSmartOrderDialog: boolean;
  smartOrderResult?: string[];
}

const initialState: ReorderState = {
  isReorderLoading: false,
  reorderResult: undefined,
  isProductsNotFoundDialogLoading: false,
  showProductsNotFoundDialog: false,
  showSmartOrderDialog: false,
  smartOrderResult: undefined,
};

// Reducers
export const reorderSlice = createSlice({
  name: 'reorder',
  initialState: initialState,
  reducers: {
    setIsReorderLoading: (state: ReorderState, action: PayloadAction<boolean>) => {
      state.isReorderLoading = action.payload;
    },
    setReorderResult: (state: ReorderState, action: PayloadAction<ReorderResult>) => {
      state.reorderResult = action.payload;
    },
    setIsProductsNotFoundDialogLoading: (state: ReorderState, action: PayloadAction<boolean>) => {
      state.isProductsNotFoundDialogLoading = action.payload;
    },
    setShowProductsNotFoundDialog: (state: ReorderState, action: PayloadAction<boolean>) => {
      state.showProductsNotFoundDialog = action.payload;
    },
    resetReorderState: (state: ReorderState) => {
      state.isProductsNotFoundDialogLoading = initialState.isProductsNotFoundDialogLoading;
      state.isReorderLoading = initialState.isReorderLoading;
      state.showProductsNotFoundDialog = initialState.showProductsNotFoundDialog;
      state.reorderResult = initialState.reorderResult;
    },
    setShowSmartOrderDialog: (state: ReorderState, action: PayloadAction<boolean>) => {
      state.showSmartOrderDialog = action.payload;
    },
    setSmartOrderResult: (state: ReorderState, action: PayloadAction<string[]>) => {
      state.smartOrderResult = action.payload;
    },
    resetSmartOrderState: (state: ReorderState) => {
      state.showSmartOrderDialog = initialState.showSmartOrderDialog;
      state.smartOrderResult = initialState.smartOrderResult;
    },
  },
});
